<template>
  <ProductSearch :scope="currentCategoryName" @onSearch="startSearch($event)"></ProductSearch>

  <CategoryNavV2 ref="categorySelector" :categories="categories" @onCategoryChanged="categoryChanged"></CategoryNavV2>

  <CommonSliderNav :has-all="true" :items="brands" @onItemChanged="brandChanged" ref="brandSelector"></CommonSliderNav>
<!--  <CommonSliderNav :has-all="true" :items="grades" @onItemChanged="gradeChanged" ref="gradeSelector"></CommonSliderNav>-->

<!--  <FilterSorter @loadProducts="loadProducts" @sortProducts="sortProducts"></FilterSorter>-->

  <div class="container-fluid no-scroll my-1">
    <div class="container px-0">
      <div class="row">
        <div class="col-12 bg-white text-center d-flex align-items-center justify-content-center flex-column py-5" v-if="products.length === 0">
          <img src="../../assets/svgs/com_ic_empty.svg" style="width: 15rem;">
          <span style="color: #888">Available soon...</span>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center px-3 bg-white"
           v-for="product in products" v-bind:key="product.stock_id" style="margin-bottom: 1px;">
        <div class="product-v2">
          <div class="line1">
            <div class="grade">{{ product.grade }}</div>
            <div class="name"> {{ product.name }}</div>
          </div>
          <div class="line2">
            <div class="spec">{{ getSimpleSpecString(product.specs) }}</div>
          </div>
          <div class="line3">
            <div v-if="isLoggedIn" class="price">
              <div>
                {{ toThousands(product.price) }}
                <span class="currency">{{ product. currency}}</span>
              </div>
            </div>
            <div v-else class="login-price"><a href="/user/login?redirect=/">Sign in to get quote</a></div>
          </div>
          <div v-if="isLoggedIn" class="line4">
            <div class="location">
              {{ product.country }}
            </div>
            <div class="sep">
              /
            </div>
            <div class="qty">
              {{ product.qty }} pcs
            </div>
          </div>
<!--          <div v-if="isLoggedIn" class="line5">-->
<!--            <BargainPrice v-model:sid="product.seller_id"-->
<!--                          v-model:bid="user.id"-->
<!--                          v-model:tid="product.stock_id"-->
<!--                          v-model:originalPrice="product.price"-->
<!--            ></BargainPrice>-->
<!--          </div>-->
        </div>
        <div v-if="isLoggedIn" class="op">
<!--          <a :href="getWhatsappLink(product)" target="_blank"><fa class="op-icon" icon="whatsapp" type="fab"></fa></a>-->
          <img src="../../assets/svgs/shopping_list_ic_add_sel.svg" @click="addToShoppingCart(product)" style="cursor: pointer">
        </div>
      </div>
      <div v-if="hasMoreItems" class="col-12 text-center my-3 small hover">
        <div class="text-dark" @click="loadMore()">
          <fa icon="angle-double-right"></fa>
          <br>More...
        </div>
      </div>
      <div v-else class="col-12 text-center my-3 small hover">
        <div class="fw-bold">
          No more items...
          <img src="../../assets/svgs/com_ic_empty.svg" style="width: 3rem;">
        </div>
        <div class="mt-3">
          <AddWishlist :isLoggedIn='isLoggedIn ? true : false'></AddWishlist>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import ProductSearch from '../../components/product/ProductSearch'
import CategoryNavV2 from '../../components/product/CategoryNavV2'
import AddWishlist from '../../components/wishlist/AddWishlist'
// import BargainPrice from '../../components/product/BargainPrice'

import {
  getBrandsByCategory,
  getCategoryAndBrandsAndGrades,
  getProducts
} from '../../api/product'

import {
  addToCart
} from '../../api/user'

import { useStore } from 'vuex'
import { computed } from 'vue'

// import photoSwipe from '@/plugins/photoswipe'
import CommonSliderNav from '../../components/product/CommomSliderNav'

import getSimpleSpecString from '../../api/specs'

export default {
  name: 'ProductWithoutPrice',
  components: {
    CommonSliderNav,
    // FilterSorter,
    CategoryNavV2,
    ProductSearch,
    AddWishlist
    // BargainPrice
  },

  mounted () {
    this.loadCategoryAndBrands()
  },

  data () {
    return {
      categories: this.cacheCategories || [],
      brands: [],
      grades: this.cacheGrades || [],
      currentCategoryId: parseInt(this.$route.params.categoryId) || 0,
      currentCategoryName: undefined,
      currentBrandId: 0, // 0表示所有品牌，默认不选中
      currentGradeId: -1,
      products: [],

      sorters: {},
      filters: {},

      currentPage: 1,
      itemsPerPage: 20,
      totalItems: 0,
      hasMoreItems: false,
      pager: {
        limit: this.itemsPerPage,
        offset: 0
      },

      searchKey: '',

      wishItem: ''
    }
  },

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)
    const token = computed(() => store.state.auth.token)
    const isLoggedIn = computed(() => store.state.auth.loggedIn)

    const saveCategoriesInCache = ({ categories }) => store.dispatch('reer/cacheCategories', { categories })
    const cacheCategories = computed(() => store.state.reer.categories)

    const saveGradesInCache = ({ grades }) => store.dispatch('reer/cacheGrades', { grades })
    const cacheGrades = computed(() => store.state.reer.grades)

    const cartCount = computed(() => store.state.cart.cartCount)
    const updateCartCount = (count) => store.dispatch('cart/updateCartCount', count)

    return {
      user,
      token,
      isLoggedIn,
      saveCategoriesInCache,
      saveGradesInCache,
      cartCount,
      updateCartCount,
      cacheCategories,
      cacheGrades
    }
  },

  methods: {
    getSimpleSpecString,

    getWhatsappLink (product) {
      const productInfo = encodeURI(`Stock: ${product.stock_id} \nName: ${product.name} \nSpec:  ${product.specs} \nPrice: ${product.price}\nQty: ${product.qty}`)
      const sellerPhone = product.phone.split(' ').join('')
      return `//wa.me/${sellerPhone}&text=${productInfo}`
    },

    // getThumbImageUrl (url) {
    //   if (!url) {
    //     return url
    //   } else {
    //     return url + '?x-oss-process=style/thumb'
    //   }
    // },

    startSearch (searchKey) {
      this.searchKey = searchKey
      this.loadProducts()
    },

    // showImages (images) {
    //   const swipeItems = []
    //   for (const key in images) {
    //     swipeItems.push({
    //       src: images[key] + '?x-oss-process=style/wm',
    //       w: 1024,
    //       h: 1024
    //     })
    //   }
    //   photoSwipe.preview(swipeItems)
    // },

    addToShoppingCart (product) {
      const params = {
        stock_id: product.stock_id,
        sku_id: product.sku_id,
        buyer_id: this.user.id,
        seller_id: product.seller_id,
        name: product.name,
        quantity: 1,
        price: product.price,
        status: 0
      }

      addToCart(params).then(res => {
        if (res.code === 0) {
          this.$toast.success('Successfully added to cart')
          this.updateCartCount(this.cartCount + 1)
        } else if (res.code === -1) {
          this.$toast.info('Already in your cart')
        } else {
          this.$toast.success('Failed to add to cart')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    resetPager () {
      this.currentPage = 1
      this.itemsPerPage = 20
      this.pager.limit = 20
      this.pager.offset = 0
    },

    categoryChanged (id) {
      if (id === this.currentCategoryId) {
        return
      }

      this.currentCategoryId = id
      this.currentCategoryName = this.categories.filter(item => item.id === id)[0].name
      this.$refs.brandSelector.resetSelection()
      // this.$refs.gradeSelector.resetSelection()
      this.currentBrandId = -1
      this.currentGradeId = -1

      getBrandsByCategory({ categoryId: this.currentCategoryId }).then(res => {
        if (res.code === 0) {
          this.brands = res.data.brands
          if (res.data.brands.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
      }).catch(err => {
        console.log(err)
      })

      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    gradeChanged (id) {
      this.currentGradeId = id
      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    brandChanged (id) {
      this.currentBrandId = id
      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    loadCategoryAndBrands () {
      this.$loading.show('Loading category and brand list...')
      getCategoryAndBrandsAndGrades({ categoryId: this.currentCategoryId > 0 ? this.currentCategoryId : undefined })
        .then(res => {
          if (res.code === 0) {
            this.categories = res.data.categories

            this.saveCategoriesInCache({ categories: this.categories })

            // 假如没有选中的类别，就设置第一个为选中
            if (this.currentCategoryId === 0) {
              if (this.categories.length > 0) {
                this.currentCategoryId = this.categories[0].id
                this.currentCategoryName = this.categories[0].name
              }
            }

            // 设置选中的categoryId
            this.$refs.categorySelector.setInitialCategoryId(this.currentCategoryId)

            this.brands = res.data.brands
            this.grades = res.data.grades

            this.saveGradesInCache({ grades: this.grades })

            this.loadProducts()
          } else {
            this.$loading.hide()
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },

    loadProducts (param = undefined) {
      if (this.currentCategoryId === 0) {
        console.log('No category is selected')
        return
      }

      this.$loading.show('Loading products...')

      this.filters = { categoryId: this.currentCategoryId }
      if (this.currentBrandId && this.currentBrandId > 0) {
        this.filters = { brandId: this.currentBrandId, ...this.filters }
      }

      if (this.currentGradeId && this.currentGradeId > 0) {
        this.filters = { gradeId: this.currentGradeId, ...this.filters }
      }

      if (this.searchKey && this.searchKey.length > 0) {
        this.filters = { search: this.searchKey, ...this.filters }
      }

      if (param === 'new') {
        delete this.sorters.sold_time
        this.sorters = { publish_date: 'desc', ...this.sorters }
      } else if (param === 'best') {
        delete this.sorters.publish_date
        this.sorters = { sold_time: 'desc', ...this.sorters }
      } else if (param === 'all') {
        delete this.sorters.sold_time
        delete this.sorters.publish_date
      }

      this.resetPager()
      this.hasMoreItems = true

      const params = {
        filters: this.filters,
        sorters: this.sorters,
        pager: this.pager
      }

      // console.log(params)
      getProducts(params).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.products = res.data.products
          if (this.products.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    loadMore () {
      this.$loading.show('Loading more products...')
      this.pager.offset += this.itemsPerPage
      const params = {
        filters: this.filters,
        sorters: this.sorters,
        pager: this.pager
      }

      getProducts(params).then(res => {
        if (res.code === 0) {
          if (res.data.products.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
          this.products = this.products.concat(res.data.products)
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    sortProducts (param) {
      if (param === 1) {
        this.sorters.unit_price = 'asc'
      } else if (param === 2) {
        this.sorters.unit_price = 'desc'
      } else {
        delete this.sorters.unit_price
      }
      this.loadProducts()
    }
  }
}
</script>

<style scoped lang="scss">
.product-v2 {
  margin: 1px 0;
  padding: 1rem 1.5rem 1rem 0;
  .line1 {
    display: flex;
    align-items: center;
    .grade {
      text-align: center;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.9rem;
      padding: 0.1rem;
      color: white;
      background: black;
      border-radius: 0.4rem;
      font-weight: bold;
    }

    .name {
      margin-left: 0.5rem;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .line2 {
    display: flex;
    align-items: center;
    .spec{
      font-size: 0.9rem;
      padding: 0.5rem 0;
      color: #999;
    }
  }

  .line3{
    .price{
      display: flex;
      color: #DE350B;
      font-size: 1.3rem;

      .currency {
        font-size: 0.8rem;
      }
    }

    .login-price{
      font-size: 1rem;
      color: #de350b;

      a {
        color: #F1651A;

        &:hover{
          opacity: 0.7;
        }
      }
    }
  }

  .line4{
    margin-top: 0.5rem;
    display: flex;
    .location{
      font-size: 0.9rem;
    }
    .sep {
      margin: 0 0.3rem;
    }
    .qty{
      font-size: 0.9rem;
    }
  }

  .line5 {
    margin-top: 0.8rem;
  }
}

.op {
  .op-icon{
    height: 3rem;
    color: #DE350B;
  }
}
</style>
