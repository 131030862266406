<template>
  <div class="text-center" style="background: #333">
    <img src="../assets/about.jpg" style="width: 100%; overflow: hidden; margin: 0 auto">
  </div>
  <div class="about-content" style="margin-bottom: 0.5rem;">
    REER was founded by a team of entrepreneurs who have many years of compound experience in both pre-owned electronics
    and cutting-edge information technologies.

    <br/>
    <br/>
    In the last few years REER focused on trading pre-owned electronics, and established reliable & stable supply of good quality
    used electronics. Before, buyers usually wouldn't know the actual condition of the goods until they receive it,
    that hinder buyers to buy more, especially for buyers from other countries.
    REER precisely solves this issue by standard grading, quality refurbishing and comprehensive quality control.
    Now buyers don't need to worry about quality but just order any items they want on ReerGood.com.
    <br/>
    <br/>

    ReerGood's mission is to provide a sustainable source of good quality pre-owned / refurbished electronics to worldwide wholesalers and retailers.
  </div>
</template>

<script>

export default {
  name: 'About'
}
</script>

<style scoped>

.about-title {
  font-size: 3rem;
  text-align: center;
  margin: 2rem auto;
}

.about-content {
  padding: 1rem;
  background: #fff;
}

</style>
