<template>
  <HeaderV2></HeaderV2>
  <ProductWithoutPrice></ProductWithoutPrice>
<!--  <DailyProductList></DailyProductList>-->
  <FooterInfo></FooterInfo>
  <Footer></Footer>
</template>

<script>
import HeaderV2 from '../components/HeaderV2'
import FooterInfo from '../components/FooterInfo'
import Footer from '../components/Footer'
import ProductWithoutPrice from '@/views/product/ProductWithoutPrice'

export default {
  name: 'Daily',
  components: { ProductWithoutPrice, Footer, FooterInfo, HeaderV2 }
}
</script>

<style scoped>

</style>
