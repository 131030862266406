<template>
  <div class="text-center" style="background: #333">
    <img src="../assets/faq.jpg" style="width: 100%; overflow: hidden; margin: 0 auto;">
  </div>
  <div class="faq-item">
    <div class="title">
      Who is REER?
    </div>
    <div class="content">
      REER is a dedicated platform offering affordable and good quality pre-owned electronics to worldwide wholesaler and retailers.
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      Why REER?
    </div>
    <div class="content">
      REER has  wide range and sustainable supply of fully tested, good quality pre-owned electronics.
      <br>
      REER offer very competitive prices helping REER's customers to earn more than ever.
      <br>
      REER provide logistics consultancy to deliver goods to any country REER customers reside.
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      How does REER perform quality control?
    </div>
    <div class="content">
      REER controls the quantity in two points: cosmetic and functionality.
      <ul style="list-style: none;">
        <li style="margin-left: 0px;">
          <span class="fw-bold">1. Cosmetic: </span> All the defects has been fixed after the renewing procedures are done, then  a full appearance inspection will be executed to determine its final grade.
        </li>
        <li>
          <span class="fw-bold">2. Functionality: </span>
          REER makes use of professional software to automatically testing almost most of the functionalities which includes keyboard, sound, display, wifi etc.
        </li>
      </ul>
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      Why REER prices are most competitive?
    </div>
    <div class="content">
      REER connect customers directly to our first hand and certified global suppliers. No middleman to cut off your profit.
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      Does REER products have warranty?
    </div>
    <div class="content">
      Most of REER goods offer a warranty. Please check the
      detailed information of each product and consult the sales via whatsapp.
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      How do I ship the order?
    </div>
    <div class="content">
      You have two ways to ship the order.
      <ul style="list-style: none;">
        <li>
          1. Use our recommended third-party cargo company to ship the order.
        </li>
        <li>
          2. If you prefer your own cargo company, we will deliver to the company for further relaying.
        </li>
      </ul>
    </div>
  </div>
  <div class="faq-item">
    <div class="title">
      Can I pickup the order from REER's location?
    </div>
    <div class="content">
      Yes, you can also pickup from our location. Please contact our customer service before pickup.
    </div>
  </div>
  <div class="faq-item" style="margin-bottom: 0.5rem;">
    <div class="title">
      What's REER Global?
    </div>
    <div class="content">
      REER Global is ReerGood's mother company which consists of recycle, renewing and reuse sections. ReerGood online transaction platform, along with several sale branches in different countries, constitutes the reuse section.
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ'
}
</script>

<style scoped lang="scss">
.faq-title {
  font-size: 3rem;
  text-align: center;
  margin: 2rem auto;
}

.faq-item {
  background: #fff;
  margin-top: 0.5rem;

  .title {
    padding: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .content {
    padding: 1rem;
    line-height: 2rem;
  }
}

</style>
